import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAWPJDoXQHb2RD3L6igJnWJQle7gWJUhWI",
    authDomain: "photoghan.firebaseapp.com",
    databaseURL: "https://photoghan.firebaseio.com",
    projectId: "photoghan",
    storageBucket: "photoghan.appspot.com",
    messagingSenderId: "167730227169",
    appId: "1:167730227169:web:3d8ab45a822ec7a48754a0"
});

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();

export { db, auth, storage };